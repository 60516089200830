
  import axios, { AxiosError } from 'axios';
  import { defineComponent, ref, onMounted, watch, Ref } from 'vue';
  import { useRoute } from "vue-router";
  import useAlert from "@/composables/Alert";
  import {
    CampHeader,
    CampEmptyListFeedback,
    CampTable,
    CampTableTd,
    CampTableTh,
    CampTableSortRowsByColumn,
    CampFooterPaginationControl,
  } from '@/components';
  import { useProductStore } from "@/store/ProductStore"
  import StockInfo from '@/views/Stock/Components/StockInfo.vue'
  import moment from 'moment';
  import { useLoaderStore } from "@/store/LoaderStore";
  import { useStoreStore } from '@/store/StoreStore';
  import { useCompanyStore } from '@/store/CompanyStore';
  import { IObjEmit, ISortComponentCore } from '@/components/Table/CampTableSortRowsByColumn.vue';
  import { campGoToTopByScrollMode } from '@/composables/Helpers';
  import { date } from 'yup';
  
  type TDateParams = {
    rangeDate: Date[]
    startDate: Date | null
    endDate: Date | null
  }
  
  export default defineComponent({
    name: "stockExpireList",
    components: {
      CampHeader,
      CampEmptyListFeedback,
      CampTable,
      CampTableTd,
      CampTableTh,
      CampTableSortRowsByColumn,
      StockInfo,
      CampFooterPaginationControl
    },
    setup() {
      const router = useRoute();
      const useStore = useProductStore()
      const openModalInfoRef = ref(false)
      const stockList = ref()
      const detailList = ref()
      const loaderStore = useLoaderStore()
      const isLoadList = ref(false)
      const filterDom = ref('')
      const pageID = ref(1)
      const pageTotal = ref(1)
      const storeStore = useStoreStore()
      const companyStore = useCompanyStore()
      const inputEventFromXMLFileList = ref <Event | null>(null)
      const toggleModalUploadInvoice = ref(false)
      const sortComponentCore = ref<ISortComponentCore>({
        currentColumn: "",
        rows: {},
        chronology: []
      })
      const searchParams = ref<any>(null)
      const filteredStock = ref();
      const searchCompetitor = ref<String | null>(null);
      const selectedMovementTypes = ref([])
      const searchResponsible = ref<String | null>(null);
      const responsibleError = ref('')
      const movement = {
        1: {value: 'input', label: 'Entrada'},
        2: {value: 'output', label: 'Saída'},
        3: {value: 'return', label: 'Estorno'},
      }
  
      //Fitragem
      const filterStock = () => {
        validateResponsible(searchResponsible.value);
        if (responsibleError.value) {
          showTimeAlert("O campo nome do responsável deve conter apenas letras.")
          return
        }
        searchCompetitor.value ?  searchCompetitor.value.toLowerCase() : '';
        searchResponsible.value ? searchResponsible.value.toLowerCase() : '';
        filteredStock.value = stockList.value?.filter((stock) => {
          handleDateParams(dateParams, completedDateParams)
           const matchesMovementType = selectedMovementTypes.value.length
           const matchesResponsible = stock.competitor.toLowerCase().includes(searchResponsible.value)
           const matchesMovementId =  stock.movement_id.toLowerCase().includes(searchCompetitor.value)
           return matchesMovementType && matchesResponsible && matchesMovementId;
        })
      }
  
      const completedDateParams = ref<TDateParams>({
        rangeDate: [],
        startDate: null,
        endDate: null
      })
  
      const dateParams = ref<TDateParams>({
        rangeDate: [],
        startDate: null,
        endDate: null,
      })
  
      const handleDateParams = (dateParams: Ref<TDateParams>, completedDateParams: Ref<TDateParams>) => {
        if(Array.isArray(dateParams.value.rangeDate) && dateParams.value.rangeDate.length > 1){
          completedDateParams.value.startDate = dateParams.value.rangeDate[0]
          completedDateParams.value.endDate = dateParams.value.rangeDate[1]
        } else {
          completedDateParams.value.startDate = null
          completedDateParams.value.endDate = null
        }
      }
  
      const clearFilters = () => {
        searchCompetitor.value = null
        selectedMovementTypes.value = []
        searchResponsible.value = null
        dateParams.value.rangeDate = []
        completedDateParams.value.startDate = null
        completedDateParams.value.endDate = null
        filteredStock.value = stockList.value
      }
  
      const validateResponsible = (value) => {
        const regex = /\d/;
        if (regex.test(value)) {
          responsibleError.value = 'Não é permitido inserir números neste campo.';
        } else {
          responsibleError.value = '';
        }
      }
  
      //Fim da filtragem
  
      function openModalInfo(list) {
        openModalInfoRef.value = !openModalInfoRef.value
        detailList.value = list
      }
  
      function convertTimeToBrazil(databaseTime) {
            let dbDate = new Date(databaseTime);
            let timezoneOffset = dbDate.getTimezoneOffset();
            let timezoneOffsetMs = timezoneOffset * 60 * 1000;
            let saoPauloTimeMs = dbDate.getTime() - (3 * 60 * 60 * 1000) + timezoneOffsetMs;
            let saoPauloDate = new Date(saoPauloTimeMs);
            
            // Obter o mês como número (adicionando 1 porque os meses são indexados a partir de 0)
            let monthNumber: any = saoPauloDate.getMonth() + 1;
  
            monthNumber  = monthNumber < 10? '0' + monthNumber: monthNumber
  
            let newDate = saoPauloDate.getDate() + '/' +  monthNumber + '/' + saoPauloDate.getFullYear() + ' ' + saoPauloDate.toString().substring(15,24);
            return newDate;
        }
  
      async function getStock() {
        try {
          isLoadList.value = true
          const movementTypes = Array.isArray(selectedMovementTypes.value) ? selectedMovementTypes.value.map(String) : []
          const response = await axios.get(`/api/getExpiredStockList`,{
            params: {
              ...searchParams.value
            }
          });
          pageTotal.value = 1
          stockList.value = response.data.data
            .sort((a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf())
            .map(el => ({ ...el, name: el.user ? el.user : el.competitor }))
          sortComponentCore.value.rows = stockList.value
          filterStock()
        } catch (error) {
          if(error instanceof AxiosError) {
                showTimeAlert(error.response?.data.message, "error")
            }
        } finally {
          isLoadList.value = false
          loaderStore.close()
        }
      }
  
      async function searchStock() {
        pageID.value = 1
        handleDateParams(dateParams,completedDateParams)
        const idCompany = await companyStore.getAsyncId
        searchParams!.value = {
          idCompany: idCompany,
          idStore: storeStore.getId,
          movement_id: searchCompetitor.value,
          movement_type: selectedMovementTypes.value,
          responsible: searchResponsible.value,
          startDate: completedDateParams.value.startDate,
          endDate: completedDateParams.value.endDate,
        }
        getStock()
      }
  
      /**
       * Backup do fluxo da tratativa dos ids de company e store em Local Storage
       */
      function handleDataLocalStorage() {
        const resetCompany = ():void => {
          companyStore.setId(null)
          companyStore.setFantasyName("")
        }
        try {
          const cs = JSON.parse(localStorage.getItem("cs")!)
          if(cs) {
            companyStore.setId(cs.i)
            companyStore.setFantasyName(cs.n)
          }
        } catch (error) {
          resetCompany()
        }
  
        const resetStore = (): void => {
          storeStore.setId(null)
          storeStore.setFantasyName("")
        }
        try {
          const ss = JSON.parse(localStorage.getItem("ss")!)
          if(ss) {
            storeStore.setId(ss.i)
            storeStore.setFantasyName(ss.n)
          }
        } catch (error) {
          resetStore()
        }
      }
  
      function sortRowsByColumn(obj: IObjEmit) {
        stockList.value = obj.sortedList
        sortComponentCore.value = obj.sortComponentCore
      }
  
      async function handlePaginationRendering(ID: number) {
        loaderStore.open()
        pageID.value = ID
        searchCompetitor.value = searchParams.value.movement_id
        selectedMovementTypes.value = searchParams.value.movement_type
        searchResponsible.value = searchParams.value.responsible
        dateParams.value.rangeDate = [searchParams.value.startDate, searchParams.value.endDate]
        await getStock()
        campGoToTopByScrollMode(false)
      }
  
      /**
       * Funcionalidade desativada
       */
      // onBeforeMount(() => handleDataLocalStorage())
      
      onMounted(async () => {
        isLoadList.value = true
        await searchStock()
        loaderStore.close()
        // filterStock()
      })
  
    //   watch(() => { storeStore.getId }, () => isLoadList.value = true, {deep: true})
  
    watch(() => companyStore.updateFilter, () => {
        searchStock();
    }, {deep: true});

  
      // watch([searchResponsible, selectedMovementTypes, searchCompetitor], async () => {
      //     pageID.value = 1
      // }, { deep:true})
  
      const { showTimeAlert } = useAlert()
      const itemDel = ref<any>(null)
      const refModalDel = ref<HTMLElement | null>(null)
  
      const handleXMLFileChange = async (evt: Event) => {
        inputEventFromXMLFileList.value = evt
        toggleModalUploadInvoice.value = !toggleModalUploadInvoice.value
      }
  
      return {
        stockList,
        getStock,
        openModalInfo,
        openModalInfoRef,
        detailList,
        convertTimeToBrazil,
        isLoadList,
        filterDom,
        moment,
        inputEventFromXMLFileList,
        toggleModalUploadInvoice,
        handleXMLFileChange,
        sortRowsByColumn,
        sortComponentCore,
        pageID,
        pageTotal,
        handlePaginationRendering,
        filterStock,
        searchCompetitor,
        searchResponsible,
        selectedMovementTypes,
        movement,
        dateParams,
        clearFilters,
        validateResponsible,
        responsibleError,
        searchStock
      }
    }
  })
  